.App {
  /* height: 100vh; */
  /* overflow: scroll; */
  text-align: center;
}

.cta-button {
  height: 45px;
  border: 0;
  width: auto;
  padding-left: 40px;
  padding-right: 40px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  color: white;
}

.connect-wallet-button {
  background-image: linear-gradient(
    to right,
    #ff8177 0%,
    #ff867a 0%,
    #ff8c7f 21%,
    #f99185 52%,
    #cf556c 78%,
    #b12a5b 100%
  );
  background-size: 200% 200%;
  animation: gradient-animation 4s ease infinite;
}

.connect-wallet-button-divider-left {
  border-top: 1px solid white;
  width: 45%;
  float:left;
}

.connect-wallet-button-divider-right {
  border-top: 1px solid white;
  width: 45%;
  float:right;
}

.connect-wallet-button-social {
  color: white;
  background: -webkit-linear-gradient(left, #67aeff, #5255ff);
  background-size: 200% 200%;
  max-width: 300px;
}

.connect-wallet-button-web3 {
  color: white;
  background: transparent;
  background-size: 200% 200%;
  max-width: 300px;
  border: 1px solid white;
}

.connect-wallet-container {
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: 550px;
}

.connect-wallet-container img {
  padding-bottom: 20px;
}

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.header {
  margin: 0;
  font-size: 50px;
  font-weight: bold;
}

.header-container {
  padding-top: 30px;
}

.sub-text {
  font-size: 25px;
}
